/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages



        // Video Modal - load in modal and resize the iframe
        function videoModal() {
          $('#videoModal').on({
            'shown.bs.modal': function (event) {
              var button = $(event.relatedTarget),
                  src = button.data('source');
              $('#videowrapper').attr('src', src);
            },
            'hidden.bs.modal': function (e) {
              $('#videowrapper').attr('src', '');
            }
          });
        }

          // Make Bootstrap Dropdowns Linked and hoverable
        $('#PrimaryNavigation .dropdown-toggle').click(function() {
            var location = $(this).attr('href');
            window.location.href = location;
            return false;
        });

        $('#PrimaryNavigation ul.nav li.dropdown').hover(function() {
          $(this).find('.dropdown-menu').stop(true, true).delay(50).fadeIn(200);
        }, function() {
          $(this).find('.dropdown-menu').stop(true, true).delay(50).fadeOut(200);
        });

        // Carousel text replacement
        function rotatingBanner() {

          function toggleCaption() {
            var caption = $('#rotating-banner').find('.active .carousel-text');
            var cta = $('#rotating-banner').find('.active .cta-button');
            $('#banner-cta .carousel-text').hide().html(caption.html()).fadeIn('slow');
            $('#banner-cta .cta-button').hide().html(cta.html()).fadeIn('slow');

            if ( $('#rotating-banner .item.active').hasClass("no-cta")) {
              $('#banner-cta').addClass("no-cta").removeClass("has-cta");
            } else {
              $('#banner-cta').removeClass("no-cta").addClass("has-cta");
            }
          }
          toggleCaption();
            $('#rotating-banner').carousel({
              pause:false,
              interval: 7000
          }).on('slid.bs.carousel', function() {
            toggleCaption();
          });
        }

        // Resize main banner to window height
        function bannerHeight() {
          var windowHeight = $(window).innerHeight(),
              ctaHeight = $('#banner-cta').outerHeight(),
              innerHeight = windowHeight;
          $('#rotating-banner').css('height', windowHeight);
          $('#rotating-banner .carousel-inner').css('height', innerHeight);

          // Run these functions when the window resizes
          $(window).resize(function() {
            // Resize main banner to window height
             bannerHeight();
          });
          $(window).scroll(function() {
            if ($(document).scrollTop() > 50) {
              $('nav').addClass('scroll-re-size');
            } else {
              $('nav').removeClass('scroll-re-size');
            }
          });
        }

        // Carousel text replacement
        rotatingBanner();

        // Video Modal - load in modal and resize the iframe
        videoModal();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        // hide our element on page load
        $('.col-sm-push-6').css('opacity', 0);

        $('.col-sm-push-6').waypoint(function(direction) {
          if (direction === 'down') {
            $(this.element).addClass('fadeInRight');
            $(this.element).removeClass('fadeOutRight');

          /*
          }else if (direction === 'up') {
            $(this.element).addClass('fadeOutRight');
            $(this.element).removeClass('fadeInRight');
          */
          }
        },{ offset: '50%' });

        // hide our element on page load
        $('.col-sm-pull-6').css('opacity', 0);

        $('.col-sm-pull-6').waypoint(function(direction) {
          if (direction === 'down') {
            $(this.element).addClass('fadeInLeft');
            $(this.element).removeClass('fadeOutLeft');

          /*
          }else if (direction === 'up') {
            $(this.element).addClass('fadeOutLeft');
            $(this.element).removeClass('fadeInLeft');
          */
          }
        },{ offset: '50%' });

        // Lightbox for Bootstrap - https://github.com/ashleydw/lightbox/releases/tag/v4.0.2
        $(document).on('click', '[data-toggle="lightbox"]', function(event) {
          event.preventDefault();
        $(this).ekkoLightbox();
        });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        // Resize main banner to window height
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
